<template>
    <BT-Blade-Items
        addBladeName="supply-line"
        :bladesData="bladesData"
        bladeName="supply-lines"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Product', value: 'productID', itemText: 'productName' },
            { text: 'Departure Location', value: 'departureLocationID', navigation: 'locations', itemText: 'locationName' },
            { text: 'Destination Location', value: 'destinationLocationID', navigation: 'locations', itemText: 'locationName' },
            { text: 'Supplier', value: 'supplyAgreementID', navigation: 'suppliers', single: true, itemText: 'seller.sellerName' },
            { text: 'Supplier Product', value: 'sourceProductID', navigation: 'public-products', single: true, itemText: 'productName' },
            { text: 'Minimum Order Quantity', value: 'minimumOrderQuantity' },
            { text: 'Quantity Increment', value: 'quantityIncrement' }]"
        navigation="supply-lines"
        useServerPagination
        title="Supply Lines"
        :defaultBladeWidth="350">
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Supply-Lines-Blade',
    props: {
        bladesData: null
    }
}
</script>