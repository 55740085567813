var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"supply-line","bladesData":_vm.bladesData,"bladeName":"supply-lines","canAdd":"","canDelete":"","canSearchLocal":"","headers":[
        { text: 'Product', value: 'productID', itemText: 'productName' },
        { text: 'Departure Location', value: 'departureLocationID', navigation: 'locations', itemText: 'locationName' },
        { text: 'Destination Location', value: 'destinationLocationID', navigation: 'locations', itemText: 'locationName' },
        { text: 'Supplier', value: 'supplyAgreementID', navigation: 'suppliers', single: true, itemText: 'seller.sellerName' },
        { text: 'Supplier Product', value: 'sourceProductID', navigation: 'public-products', single: true, itemText: 'productName' },
        { text: 'Minimum Order Quantity', value: 'minimumOrderQuantity' },
        { text: 'Quantity Increment', value: 'quantityIncrement' }],"navigation":"supply-lines","useServerPagination":"","title":"Supply Lines","defaultBladeWidth":350}})}
var staticRenderFns = []

export { render, staticRenderFns }